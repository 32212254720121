
export class ErrorHeaders {
  static EmptyAddress = "Please enter a Binance Chain BNB Address";
  static InvalidAddress = "Invalid Binance Chain BNB Address";
  static UnableToFetchPrice = "Unable to fetch current price";
  static NoRewards = "No Rewards for the specified address";
}

// TODO: NEED TO SET ERROR (NOREWARDS) ON NO DATA
export function Error(props) {

  return(
    <div className="bg-red-100 p-5 w-full mb-5 rounded-xl transition-opacity" onClick={() => props.onCancel()}>
      <div className="flex justify-between">
        <div className="flex space-x-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="flex-none fill-current text-red-500 h-4 w-4 mt-0.5">
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z" />
          </svg>
          <div className="leading-tight flex flex-col space-y-2">
            <div className="text-sm font-medium text-red-700">{props.headerText}</div>
            {props.bodyText &&
              <div className="flex-1 leading-snug text-sm text-red-600">{props.bodyText}</div>
            }
          </div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="flex-none fill-current text-red-600 h-3 w-3 mt-1">
            <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
          </svg>
        </div>
      </div>
    </div>
  )
}
